.services {
  padding: 100px 0;

  .services__block {
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .services__item {
      width: 49%;
      background: #fff;
      border-radius: 10px;
      margin-bottom: 20px;
      color: $black;
      text-decoration: none;
      padding: 20px 30px 20px 20px;
      display: flex;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0);

      .services__img {
        width: 177px;
        flex: 0 0 177px;
        text-align: center;
      }

      .services__desc {
        padding-left: 20px;

        .services__title {
          transition: all .2s ease;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 18px;
          position: relative;
          line-height: 24px;

          &:after {
            content: '';
            margin-top: 10px;
            display: block;
            width: 55px;
            height: 2px;
            background: $orange;
          }
        }
        p {
          line-height: 20px;
          font-size: 15px;
        }
      }

      &:hover {
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);

        .services__desc {

          .services__title {
            color: $orange;
          }
          }
        }
    }
  }
}

@media (max-width: 1024px) {
  .services .services__block .services__item {
    width: 100%;
    padding: 20px 10px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .services .services__block .services__item .services__desc .services__title:after {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .services .services__block .services__item .services__desc {
    padding-left: 0;
  }
  .services .services__block .services__item .services__img {
    width: 100px;
    flex: 0 0 100px;

    img {
      width: 100%;
    }
  }
}

@media (max-width: 610px) {
  .services .services__block {
    padding-top: 20px;
  }
}