.top__line {
  position: relative;
  padding: 0 0 28px;
  background: $orange url(../img/layer-1.png) left bottom repeat-x;
}

.header {
  height: 100vh;
  padding-top: 114px;
  position: relative;
  z-index: 10;
  overflow: hidden;

  &.other__page {
    height: auto;
    padding-top: 112px;
    background: #fff;
    padding-bottom: 0;
    border-bottom: 1px solid $wgrey;
  }

   .header__wrap {
     padding-top: 18px;
     border-bottom: 1px solid $wgrey;
     background: #fff;
     z-index: 150;
     padding-bottom: 28px;
     transition: all 0.3s;
     position: fixed;
     top:28px;
     width: 100%;

     &.sizeh {
       padding-top: 8px;
       padding-bottom: 8px;
       top:0;

       & .wrap {
         .header__logo {
           width: 160px;
         }
         .btn__sq {
           font-size: 14px;
           padding: 8px 18px;
         }
         .header__right .header__social a {
           width: 34px;
           height: 34px;
           font-size: 14px;
           line-height: 34px;
         }
       }
     }

     & .wrap {
       display: flex;
       justify-content: space-between;
       align-items: center;

     .header__logo {
       width: 220px;

       & img {
         width: 100%;
       }
     }
     .header__right {
       display: flex;
       justify-content: space-between;
       align-items: center;

       .header__phone {
         line-height: 20px;

         .fa-phone {
           display: none;
         }

         & > span {
           display: block;
           text-transform: uppercase;
           font-size: 12px;
           font-weight: 600;
         }

         a {
           font-size: 20px;
           font-weight: 600;
           color: $orange;
           text-decoration: none;

           &:hover {
             color: darken($orange, 5);
           }
         }
       }
       .btn__sq {
         margin: 0 70px;
       }
       .header__social {

         a {
           color:$grey;
           width: 40px;
           height: 40px;
           border:2px solid $grey;
           display: inline-block;
           text-align: center;
           line-height: 40px;
           margin: 0 2px;
           border-radius: 100px;

           &:hover:nth-child(1) {
             color: #fff;
             border:2px solid #4267B2;
             background: #4267B2;
           }

           &:hover:nth-child(2) {
             color: #fff;
             border:2px solid #FF0000;
             background: #FF0000;
           }

           &:hover:nth-child(3) {
             color: #fff;
             border:2px solid #4A76A8;
             background: #4A76A8;
           }

           &:nth-child(1) {
             margin-right: 0;
           }

           &:last-child {
             margin-right: 0;
           }
         }
       }
     }
   }
   }

  .calc__block {
    background: url("../img/calc_bg.jpg") center center repeat-x;
    height: 100%;
    background-size: contain;
    display: flex;
    align-items: center;

    .wrap {
      display: flex;
      margin-top: -10%;
      justify-content: space-between;
      width: 100%;

      .calc__title {
        width: 100%;

        .calc__top {
          font-size: 56px;
          font-weight: 600;

          span {
            font-weight: 400;
            color: $orange;
          }
        }
        p {
          font-size: 20px;
        }
      }
      .calc__calc {
        width: 470px;
        flex: 0 0 470px;
        margin-left: 130px;
        border-radius: 5px;
        padding: 25px 30px;
        background:#fff;
        box-shadow: 0 3px 6px rgba(52, 74, 95, 0.2);
        position: relative;

        .calc__form {
          padding-top: 20px;

          .form__dd {

            display: flex;
            justify-content: space-between;

            .form__item {
              width: 100%;
            }

            .plms {
              width: 150px;
              flex: 0 0 150px;
              position: relative;
              padding: 0 25px 0 20px;

              .desc__count__d {
                position: absolute;
                font-size: 11px;
                line-height: 11px;
                top: -18px;
                color: #b4b4b4;
              }

              .plus {
                cursor: pointer;
                background: $orange;
                color: #fff;
                border-radius: 2px;
                width: 18px;
                height: 18px;
                text-align: center;
                line-height: 19px;
                font-size: 13px;
                position: absolute;
                right: 0;
                top:10px;

                &:active {
                  top:11px;
                }
              }

              .minus {
                cursor: pointer;
                background: $orange;
                color: #fff;
                border-radius: 2px;
                width: 18px;
                height: 18px;
                text-align: center;
                line-height: 19px;
                font-size: 13px;
                position: absolute;
                right: 0;
                bottom:10px;

                &:active {
                  bottom:9px;
                }
                &.disable-minus {
                  background: $grey;
                  pointer-events: none;
                  cursor: default;
                }
              }
            }
          }
          .check {
            .checkboxf {
              display: flex;
              flex-wrap: wrap;

              label {
                width: 49%;
              }
            }
          }
        }

        .calc__title {
          text-transform: uppercase;
          font-size: 20px;
          text-align: center;
        }

        .calc__cat {
          position: absolute;
          right: -117px;
          bottom: 0;
          height: 180px;
        }
      }
    }
  }
}


.header .header__burger {
  display: none;
}

@media (max-width: 1600px) {

}

@media (max-width: 1200px) {
  .header .header__wrap .wrap .header__right .btn__sq {
    margin: 0 30px;
  }
  .header .calc__block .wrap .calc__title .calc__top {
    font-size: 45px;
  }
  .header .calc__block .wrap .calc__title p {
    font-size: 18px;
  }
  .header .calc__block {
    background-size: cover;
  }
}

@media (max-width: 991px) {
  .header {
    padding-top: 0;
  }
  .header .header__wrap .wrap .header__right .header__social {
    margin-right: 45px;
  }
  .header .header__wrap .wrap .header__right .header__social a {
    width: 34px;
    height: 34px;
    font-size: 14px;
    line-height: 34px;
  }

  .header .header__wrap .wrap .header__right .header__phone .fa-phone {
    display: block;
  }
  .header .header__wrap .wrap .header__right .header__phone span {
    display: none;
  }
  .header .header__wrap .wrap .header__right .btn__sq span {
    display: none;
  }
  .header .header__wrap .wrap .header__logo {
    width: 160px;
  }
  .header .header__wrap .wrap .btn__sq, .header .header__wrap.sizeh .wrap .btn__sq {
    padding: 5px 20px;
    font-size: 14px;
    margin: 0 15px !important;
  }
  .header .calc__block .wrap .calc__calc {
    display: none;
  }
  .header.other__page {
    padding-top: 94px;
  }
}

@media (max-width: 610px) {
  .header .header__wrap .wrap .header__right .header__social {
    display: none;
  }
  .header .header__wrap .wrap .btn__sq, .header .header__wrap.sizeh .wrap .btn__sq {
    margin: 0 50px 0 15px!important;
  }
  .header .calc__block .wrap .calc__title .calc__top {
    font-size: 40px;
  }
  .header .calc__block .wrap .calc__title p {
    font-size: 15px;
  }
}

@media (max-width: 500px) {
  .top__line {
    display: none;
  }
  .header .header__wrap {
    padding-top: 8px;
    padding-bottom: 8px;
    top: 0;
  }

  .header.other__page {
    padding-top: 64px;
  }

}

@media (max-width: 450px) {
  .header .header__wrap .wrap .header__logo, .header .header__wrap.sizeh .wrap .header__logo {
    width: 130px;
  }
  .header .header__wrap .wrap .btn__sq, .header .header__wrap.sizeh .wrap .btn__sq {
    margin: 0 44px 0 10px!important;
    padding: 3px 10px;
    font-size: 12px;
  }
  .header.other__page {
    padding-top: 57px;
  }
}