.reservation__block {
  width: 100%;
  padding: 25px 30px;
  background:#fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(52, 74, 95, 0.2);
  position: relative;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .messages-block .susses {
    width: 93% !important;
    height: 72px !important;
    bottom: 0 !important;
    left: 9px !important;
  }

  .btn {
    display: inline-block;
    width: auto;
  }

  .calc__form {
    width: 50%;
    padding-right: 2%;
    border-right: 1px solid #eaeaea;

    .form__dd {

      display: flex;
      justify-content: space-between;

      .form__item {
        width: 100%;
      }

      .plms {
        width: 150px;
        flex: 0 0 150px;
        position: relative;
        padding: 0 25px 0 20px;

        .desc__count__d {
          position: absolute;
          font-size: 11px;
          line-height: 11px;
          top: -18px;
          color: #b4b4b4;
        }

        .plus {
          cursor: pointer;
          background: $orange;
          color: #fff;
          border-radius: 2px;
          width: 18px;
          height: 18px;
          text-align: center;
          line-height: 19px;
          font-size: 13px;
          position: absolute;
          right: 0;
          top:10px;

          &:active {
            top:11px;
          }
        }

        .minus {
          cursor: pointer;
          background: $orange;
          color: #fff;
          border-radius: 2px;
          width: 18px;
          height: 18px;
          text-align: center;
          line-height: 19px;
          font-size: 13px;
          position: absolute;
          right: 0;
          bottom:10px;

          &:active {
            bottom:9px;
          }

          &.disable-minus {
            background: $grey;
            pointer-events: none;
            cursor: default;
          }
        }
      }
    }
    .check {
      .checkboxf {
        display: flex;
        flex-wrap: wrap;

        label {
          width: 49%;
        }
      }
    }
  }

  .calc__right {
    width: 48%;
  }

  .calc__title {
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
  }

  .calc__cat {
    position: absolute;
    right: -117px;
    bottom: 0;
    height: 180px;
  }
}

@media (max-width: 991px) {
  .reservation__block .calc__form {
    width: 100%;
    padding-right: 0;
    border-right: 0;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 15px;
    margin-bottom: 0;
  }
  .reservation__block .calc__right {
    width: 100%;
  }
  .reservation__block .btn {
    display: inline-block;
    width: 100%;
  }

  .n-mb-r {
    display: none;
  }
  .n-sb-r {
    display: block;
  }
}

@media (max-width: 610px) {
  .reservation__block .calc__form .form__dd {
    flex-wrap: wrap;
  }
  .reservation__block .calc__form .form__dd .plms {
    padding: 0 25px 0 0;
    flex: 0 0 100%;
    width: 100%;
  }

  .reservation__block .calc__form .form__dd .plms .desc__count__d {
    top: -4px;
  }

  .reservation__block {
    padding: 15px;

    h4 {
      margin: 0;
    }
  }
  .reservation__block .calc__form {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 510px) {
  .reservation__block .calc__form .check .checkboxf label {
    width: 100%;
  }
  .calc__right .reserve__item {
    flex-direction: column;
    font-weight: 600;

    span {
      font-weight: 400;
    }
  }
}