.ourobjects {
  padding: 100px 0;

  .ourobjects__block {
    padding-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 17%);
    grid-gap: 1rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .ourobjects__item {

      a {
        display: block;
        background: $orange;
        border-radius: 15px;
        text-align: center;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        overflow: hidden;
        font-weight: 600;
        box-shadow: 0 4px 15px 0 rgba(255, 126, 0, 0.3);

        &:hover {
          background: darken($orange, 5);
        }

        .ourobjects__img {
          background: #fff;
          border-radius: 0 0 15px 15px;
          height: 187px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ourobjects__title {
          padding: 15px 30px;
          line-height: 22px;
        }
      }
    }
  }
}
@media (max-width: 1210px) {
  .ourobjects .ourobjects__block {
    grid-template-columns: repeat(auto-fill,18%);
  }
  .ourobjects .ourobjects__block .ourobjects__item a {
    font-size: 16px;
  }
}
@media (max-width: 1050px) {
  .ourobjects .ourobjects__block {
    grid-template-columns: repeat(auto-fill,23%);
  }
  .ourobjects .ourobjects__block .ourobjects__item {
    margin-bottom: 20px;
  }
}
@media (max-width: 910px) {
  .ourobjects .ourobjects__block {
    grid-template-columns: repeat(auto-fill,30%);
  }
}
@media (max-width: 769px) {
  .ourobjects .ourobjects__block {
    grid-template-columns: repeat(auto-fill,100%);
  }
}
