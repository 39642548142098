.menu__wrap {
   nav .nav__social {
     display: none;
   }
   nav ul {
     display: flex;
     list-style: none;
     margin: 25px 0;
     padding: 0;
     line-height: 1.2;

     li {
       margin: 0 20px;

       &:first-child {
         margin-left: 0;
       }

       a {
         color: $black;
         font-weight: 600;
         font-size: 16px;
         border-bottom: 2px solid transparent;
         text-transform: uppercase;
         text-decoration: none;

         &:hover {
           color: $orange;
           border-color: $orange;
         }
       }
     }
   }
}

@media (max-width: 991px) {
  .menu__wrap nav .nav__social {
    display: block;
    padding-top: 20px;
    margin: 15px 15px 0;
    border-top: 1px solid #e4e4e4;
      a {
        color:$grey;
        width: 40px;
        height: 40px;
        border:2px solid $grey;
        display: inline-block;
        text-align: center;
        line-height: 40px;
        margin: 0 2px;
        border-radius: 100px;

        &:hover:nth-child(1) {
          color: #fff;
          border:2px solid #4267B2;
          background: #4267B2;
        }

        &:hover:nth-child(2) {
          color: #fff;
          border:2px solid #FF0000;
          background: #FF0000;
        }

        &:hover:nth-child(3) {
          color: #fff;
          border:2px solid #4A76A8;
          background: #4A76A8;
        }

        &:nth-child(1) {
          margin-right: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  .header .header__burger {
    display: block;

    button.burger {
      display: block;
      position: fixed;
      top: 69px;
      right: 32px;
      outline: none;
      background-color: transparent;
      border: none;
      appearance: none;
      z-index: 1600;
      transition: all .3s;
    }

    button.burger.burger_st {
      top: 30px !important;
    }

    button.burger span.box {
      display: block;
      position: absolute;
      width: 26px;
      height: 26px;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      transition: .3s;
    }

    button.burger span.box span.bar {
      right: 0;
      width: 100%;
      height: 3px;
      background-color: $orange;
      position: absolute;
      transition: .5s;
      border-radius: 3px;
    }

    button.burger span.box span.bar.top {
      top: 4px;
    }

    button.burger span.box span.bar.middle {
      top: 50%;
      margin: -1px 0 0;
    }

    button.burger span.box span.bar.bottom {
      bottom: 3px;
    }

    button.burger.active span.box span.bar {
      width: 100% !important;
      background-color: $orange;
    }

    button.burger.active span.box span.bar.top {
      -webkit-transform: rotate(45deg) translate(8px, 7px);
      -ms-transform: rotate(45deg) translate(8px, 7px);
      transform: rotate(45deg) translate(8px, 7px);
      top: 0;
    }

    button.burger.active span.box span.bar.middle {
      opacity: 0;
    }

    button.burger.active span.box span.bar.bottom {
      -webkit-transform: rotate(-45deg) translate(8px, -7px);
      -ms-transform: rotate(-45deg) translate(8px, -7px);
      transform: rotate(-45deg) translate(8px, -7px);
      bottom: 2px;
    }
  }
  .menu__wrap nav ul {
    flex-direction: column;
    height: 100%;
    text-align: center;
    justify-content: center;
    margin: 0;
  }
  .menu__wrap nav ul li {
    margin: 10px 0;
  }
  .menu__wrap nav ul li a {
    font-size: 18px;
    margin: 10px 0;
    color: #000;
  }
  .menu__wrap nav {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: $white;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 100;
    transition: 0.7s cubic-bezier(0.86, 0, 0.07, 1);
  }

  .menu__wrap nav.active {
    right: 0;
    display: block;
  }
}

@media (max-width:500px) {
  .header .header__burger button.burger, .header .header__burger button.burger.burger_st {
    top: 32px!important;
  }
}

@media (max-width:450px) {
  .header .header__burger button.burger {
    top: 65px;
  }
  .header .header__burger button.burger, .header .header__burger button.burger.burger_st {
    top: 27px!important;
  }
}