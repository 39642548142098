@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic');
/**
 * Основные стили
 **/

* {
	box-sizing: border-box;
}

html {
	font-size: 16px;
}

body {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	overflow-wrap: break-word;
	word-wrap: break-word;
	background: $bg;
	line-height: 1.667;

	&.over_hide {
		overflow: hidden;
	}
}

.text-center {
	text-align: center;
}

.btn {
	background: $orange;
	display: inline-block;
	padding: 12px 40px;
	border-radius: 5px;
	color: #fff;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		background: darken($orange, 5);
	}
}

.btn__sq {
	display: inline-block;
	background: #40cc32;
	padding: 12px 25px;
	font-weight: 500;
	border-radius: 100px;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		background: darken(#40cc32, 5);
	}
}

.section__title {
	font-size: 40px;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
}

.grey__bg {
	background-color: $wgrey !important;
}

h2 {
	font-size: 24px;
	padding: 0 15px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 30px;
	margin: 0 0 20px 0;

	&::after {
		content: '';
		width: 45px;
		height: 2px;
		display: block;
		background: $orange;
	}

	&.text-center {
		text-align: center;

		&::after {
			margin: 0 auto;
		}
	}
}

a {
	transition: all .2s ease;
}

.container {
	@include min-max(320px, 100%, 0);
	overflow: hidden;
}

.wrap {
	@include min-max(320px, 1200px, 15px);
}

.wrap__s {
	@include min-max(320px, 600px, 15px);
}

@media (max-width: 1200px) {
	.btn__sq {
		padding: 7px 15px;
		font-size: 14px;
	}
}

@media (max-width: 810px) {
  h1 {
    font-size: 25px;
    line-height: 32px;
  }
	h2 {
		font-size: 32px;
		line-height: 60px;
	}
	.section__title {
		font-size: 28px;
	}
}

@media (max-width: 610px) {

	html {
		font-size: 15px;
	}
	h2 {
		font-size: 23px;
		line-height: 40px;
	}
	.section__title {
		font-size: 20px;
	}
}