.cart__block {
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;

  .cart__gallery {
    width: 500px;

    .first_foto {
      margin-bottom: 20px;
      border-radius: 10px;
      height: 400px;
      overflow: hidden;
      transition: all .2s ease;

      &:hover {
        opacity: 0.8;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      & .slick-list, & .slick-track {
        height: 100%;
      }
    }
    .sm_foto {

      .slick-slide {
        outline: none;

        img {
          margin: 0 5px;
          border-radius: 5px;
          width: 100%;
          height: 100px;
          object-fit: cover;
          cursor: pointer;
          transition: all .2s ease;
          background: #fff;
          padding: 5px;
          border: 1px solid #d6d6d6;

          &:hover {
            opacity: 0.8;
            border-color: $orange;
          }
        }

        &.slick-current img{
          border-color: $orange;
        }
      }
    }
  }

  .cart__desc {
    width: 100%;
    padding-left: 30px;

    .cart__desc__top {
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 20px;

      .cart__price {
        font-size: 40px;
        font-weight: 800;

        span {
          font-size: 26px;
        }
      }
      .cart__btn {

        .btn {
          padding: 9px 20px;
          font-size: 15px;
          margin: 0 10px;
        }
        .btn__sq {
          border-radius: 5px;
          padding: 9px 20px;
          font-size: 15px;
        }
      }
    }
    .cart__desc__bottom {
      font-size: 14px;
      color: #505050;
      padding-top: 20px;
    }
  }
}

@media (max-width: 991px) {
  .cart__block {
    flex-direction: column;
  }
  .cart__block .cart__gallery {
    width: 50%;
    margin: 0 auto;
  }
  .cart__block .cart__gallery .first_foto {
    height: 300px;
  }
  .cart__block .cart__gallery .sm_foto {
    display: none;
  }
  .cart__block .cart__desc {
    width: 100%;
    padding-left: 0;
  }
}

@media (max-width: 610px) {
  .cart__block .cart__desc .cart__desc__top .cart__btn .btn, .cart__block .cart__desc .cart__desc__top .cart__btn .btn__sq {
    display: block;
    width: 100%;
    text-align: center;
  }
  .cart__block .cart__desc .cart__desc__top .cart__btn .btn {
    margin: 0;
  }
  .cart__block .cart__desc .cart__desc__top .cart__btn .btn__sq {
    margin-bottom: 15px;
  }

  .cart__block .cart__gallery {
    width: 100%;
  }
}