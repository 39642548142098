.howitworks {
   background: url("../img/hiw.png") center  193px no-repeat;
   padding: 100px 0;

   .howitworks__block {
     display: flex;
     flex-wrap: wrap;
     padding-top: 50px;
     justify-content: space-between;

     .howitworks__item {
       width: 23%;
       display: flex;
       flex-direction: column;
       align-items: center;
       text-align: center;

       .howitworks__icon {
         width: 100px;
         height: 100px;
         padding: 20px;
         border-radius: 50%;
         background-image: linear-gradient( -45deg, rgb(255,126,0) 0%, rgb(255,0,150) 100%);

         img {
           width: 100%;
         }
       }
       .howitworks__title {
         font-size: 22px;
         text-transform: uppercase;
         font-weight: 600;
         margin-top: 20px;
       }

       &:nth-child(2) {
         margin-top: 30px;
       }

       &:nth-child(3) {
         margin-top: 60px;
       }

       &:nth-child(4) {
         margin-top: 90px;
       }
     }
   }
 }

@media (max-width: 1024px) {
  .howitworks {
    background: none;
    padding: 50px 0;
  }
  .howitworks .howitworks__block .howitworks__item {
    margin-top: 0!important;
    width: 45%;
    text-align: left;
    align-items: baseline;
    margin-bottom: 20px;
  }
  .howitworks .howitworks__block .howitworks__item .howitworks__title {
    font-size: 20px;
    margin-top: 15px;
  }
}

@media (max-width: 810px) {
  .howitworks .howitworks__block .howitworks__item {
    width: 48%;
  }
}

@media (max-width: 510px) {
  .howitworks .howitworks__block .howitworks__item {
    width: 100%;
  }
}