.footer {
  padding: 50px 0 15px;
  margin-top: 100px;
  color: $white;
  background: darken($orange, 5) url(../img/layer-2.png) left top repeat-x;

  .footer__bt {
    color: #fff;
    padding-top: 15px;
    margin-top: 15px;
    text-align: center;
    font-size: 13px;
    border-top: 1px solid #f98e27;
  }

  .footer__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .footer__item {
      width: 24%;
      font-size: 15px;

      .footer__title {
        font-size: 18px;
        font-weight: 600;
        position: relative;
        text-transform: uppercase;
        margin-bottom: 20px;

        &:after {
          content: '';
          display: block;
          width: 55px;
          height: 2px;
          background: $white;
        }
      }

      p {
        line-height: 20px;
        margin: 0;
      }

      .footer__logo {
        width: 170px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {

          a {
            color: #fff;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .footer__social {

        a {
          color:$white;
          width: 30px;
          height: 30px;
          border:1px solid $white;
          display: inline-block;
          text-align: center;
          line-height: 30px;
          margin: 0 2px;
          border-radius: 5px;

          &:hover:nth-child(1) {
            color: #fff;
            border:1px solid #4267B2;
            background: #4267B2;
          }

          &:hover:nth-child(2) {
            color: #fff;
            border:1px solid #FF0000;
            background: #FF0000;
          }

          &:hover:nth-child(3) {
            color: #fff;
            border:1px solid #4A76A8;
            background: #4A76A8;
          }

          &:nth-child(1) {
            margin-right: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
      .footer__contact {
        padding-top: 14px;

        .footer__c {
          padding: 5px 0;

          &:last-child {
            border: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .footer .footer__block .footer__item {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media (max-width: 610px) {
  .footer .footer__block .footer__item {
    width: 100%;
  }
}

@media (max-width:450px) {
  .footer .footer__top {
    flex-direction: column;
  }
  .footer .footer__top .footer__logo {
    margin-bottom: 15px;
  }
}