.questions {
  padding: 100px 0;

  .questions__block {
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .questions__lb {
      width: 49%;
    }

    .questions__item{
      position: relative;
      width: 100%;
      height: auto;
      margin-bottom: 20px;

      & > a{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        text-decoration: none;
        color: $black;
        font-weight: 600;
        -webkit-transition:all 0.2s linear;
        -moz-transition:all 0.2s linear;
        transition:all 0.2s linear;
        border: 1px solid #ddd;
        border-radius: 2px;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);

        &:hover {
          box-shadow: 0 1px 3px 0 rgba(0,0,0,0);
          color: $orange;
        }

      }
      & > a i{
        font-size: 18px;
      }

      & > a.active{
        color: $orange;
      }

      & .questions__desc{
        background-color: #fff;
        border: 1px solid #ddd;
        border-top: 0;
        border-radius: 0 0 2px 2px;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
        display:none;
      }
      & p{
        padding: 10px 15px;
        margin: 0;
        color: #333;
      }

      .questions__title {
        text-transform: uppercase;
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .questions .questions__block .questions__lb {
    width: 100%;
  }
  .questions .questions__block .questions__item .questions__title {
    font-size: 15px;
  }
}

@media (max-width: 610px) {
  .questions .questions__block {
    padding-top: 20px;
  }
}