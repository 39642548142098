#form__reserve {
  display: none;
}

input[type="text"], input[type="number"], select {
  background: transparent;
  border: 1px solid rgb(223, 223, 223);
  padding:12px 10px;
  outline: none;
  color: #000;
  width: 100%;
  font-size: 14px;
  margin: 10px 0;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(52, 74, 95, 0.2);
}
textarea {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #424242;
  padding: 10px 0;
  height: 100px;
  resize: none;
  outline: none;
  color: #fff;
  width: 100%;
  font-size: 14px;
  margin: 10px 0;
}

input[type="button"] {
  border: 0;
  cursor: pointer;
  outline: none;
  transition: all .2s ease;
  margin-top: 20px;
}

.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}
.checkbox__text {
  position: relative;
  padding: 0 0 0 28px;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 14px;
}
.checkbox__text:before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  width: 16px;
  height: 16px;
  background: $white;
  border: 2px solid $orange;
  border-radius: 2px;
}
.checkbox input:checked + .checkbox__text:after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background: #ff7e00;
  position: absolute;
  left: 5px;
  top: 6px;
}

::placeholder {
  color: #afafaf;
  font-size: 14px;
}

input {
  width: 100%;
}

.form__block {
  position: relative;
}
.messages-block {
  height: 100%;
  width: 100%;

  & .susses {
    color: #ffffff;
    text-align: center;
    font-size: 17px;
    line-height: 33px;
    background: rgb(93, 154, 57);
    border-radius: 3px;
    margin-bottom: 5px;
    width: 100%;
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    font-weight: 100;
    padding: 6px 15px;
    align-items: center;
  }

  & .error {
    color: #ffffff;
    font-size: 13px;
    background: #ec2e47;
    border-radius: 2px;
    margin-bottom: 5px;
    width: 100%;
    display: block;
    position: relative;
    font-weight: 300;
    padding: 1px 15px;
  }
}

.n-sb-r {
  display: none;
}

.messages-block2 {
  height: 100%;
  width: 100%;

  & .error {
    color: #ffffff;
    font-size: 13px;
    background: #ec2e47;
    border-radius: 2px;
    margin-bottom: 5px;
    width: 100%;
    display: block;
    position: relative;
    font-weight: 300;
    padding: 1px 15px;
  }
}

#form__reserve, .calc__right {

  .reserve__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    font-size: 13px;
    padding: 8px 0;

    &.total__r {
      font-size: 18px;
      font-weight: 600;
      border: 0;
    }
  }
}