.category {
  padding: 0;
  background: #FAFAFA;

  .breadcrumbs {
    padding: 10px 0;
    border-bottom: 1px solid #f2f2f2;

    .wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;
      font-style: italic;
      color: darken($grey, 25);

      a {
        margin:0 5px;
        color: darken($grey, 25);

        &:first-child {
          margin-left: 0;
        }
      }
      span {
        margin-left: 5px;
      }
    }
  }
  .category__block {

    .category__item {
      display: flex;
      justify-content: space-between;
      border-radius: 10px;
      overflow: hidden;
      background: #fff;
      margin-bottom: 35px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

      .category__img {
        flex: 0 0 300px;
        width: 300px;
        height: 300px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .category__desc {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: 20px;

        h2 {
          padding: 0;
          font-size: 20px;

          &::after {
            content: '';
            width: 45px;
            height: 2px;
            margin-top: 5px;
            display: block;
            background: #ff7e00;
          }
        }
        .category__dop {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .category__price {
            font-weight: 600;
            font-size: 30px;
          }

          .btn {
            padding: 9px 30px;
            font-size: 15px;
            margin: 0 10px;
          }
          .btn__sq {
            border-radius: 5px;
            padding: 9px 30px;
            font-size: 15px;
          }
        }
      }
    }
  }
  .category__description {
    background: #fff;
    padding: 20px 0;

    .wrap {
      font-size: 12px;
      color: #a5a5a5;

      h2 {
        font-size: 16px;
        padding: 0;


        &:after {
          display: none;
        }
      }
    }

  }
}

@media (max-width: 910px) {
  .category .category__block {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
  }
  .category .category__block .category__item {
    width: 48%;
    flex-direction: column;
  }
  .category .category__block .category__item .category__img {
    flex: 0 0 100%;
    width: 100%;
    height: 300px;
  }
  .category .category__block .category__item .category__desc h2 {
    padding: 0;
    font-size: 18px;

    &:after {
      display: none;
    }
  }
  .category .category__block .category__item .category__desc .category__dop {
    flex-direction: column;
  }
  .category .category__block .category__item .category__desc .category__dop .btn {
    padding: 7px 20px;
    font-size: 13px;
    margin: 0 10px 0 0;
  }
  .category .category__block .category__item .category__desc .category__dop .btn__sq {
    padding: 7px 20px;
    font-size: 13px;
  }
  .category .category__block .category__item .category__desc h2 {
    line-height: 30px;
  }
  .category .category__description .wrap h2 {
    line-height: 24px;
  }
}
@media (max-width: 769px) {
  .category .category__block {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
  }
  .category .category__block .category__item {
    width: 100%;
    flex-direction: column;
  }
  .category .category__block .category__item .category__img {
    height: 200px;
  }
}
@media (max-width: 510px) {
  .category .category__block .category__item .category__desc h2 {
    font-size: 16px;
    line-height: 25px;
  }
  .category .category__block .category__item .category__desc {
    padding: 15px;
  }
  .category .category__block .category__item .category__desc .category__dop .btn {
    margin: 0 0 10px 0;
  }
  .category .category__block .category__item .category__desc .category__dop .btn, .category .category__block .category__item .category__desc .category__dop .btn__sq {
    display: block;
    font-size: 15px;
    text-align: center;
  }
  .category .category__block .category__item .category__desc .category__dop .category__btn {
    width: 100%;
  }
}