.alusing {
  padding: 100px 0;

  .alusing__block {
    padding: 50px 0 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .alusing__item {
      width: 23%;
      padding: 60px 0;
      background: #fff;
      text-align: center;
      border-radius: 10px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

      .alusing__title {
        font-size: 80px;
        color: $orange;
        text-align: center;
        line-height: 50px;

        span {
          display: block;
          font-size: 18px;
          color: $black;
        }
      }

      &:last-child {
        border-right: 0;
      }

      .alusing__icons {

        a {
          color: #fff;
          text-decoration: none;
          display: inline-block;
          width: 24px;
          height: 24px;
          font-size: 13px;
          line-height: 26px;
          text-align: center;
          margin: 0 2px;
          border-radius: 3px;

          &:hover {
            opacity: 0.8;
          }

          &.vk {
            background: #4A76A8;
          }

          &.odk {
            background: #F7931E;
          }

          &.tw {
            background: #4CC7E9;
          }
          &.inst {
            background: #C34072;
          }

          &.ut {
            background: #FF0000;
          }

          &.boo {
            background: #FCD22E;
          }
          &.zzo {
            background: #67A743;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .alusing {
    padding: 50px 0;
  }
}

@media (max-width: 991px) {
  .alusing .alusing__block .alusing__item {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media (max-width: 510px) {
  .alusing .alusing__block .alusing__item {
    width: 100%;
  }
}