@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Semibold.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Semibold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Thin.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Regular.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Light.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Bold.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Medium.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

